import styled from "@emotion/styled"
import theme from "../../../styles/theme"

const StyledHeader = styled.header`
    height: 80px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 9999;

    background-color: ${theme.palette.common.purple};
    padding: 0 30px;
    
    svg {
        width: 154px 
    }

    button {
        padding: 4px 8px;
        &:disabled {
            background-color: ${theme.palette.common.orange};
        }
    }

    nav {
        
        display: flex;
        align-items: center;
        gap: 20px;
        &>a{
            color: ${theme.palette.common.white};
            font-size: 16px;
            line-height: 1.4;
        }
        
    }
    @media screen and (min-width: 480px) {
        padding: 0 80px;

        svg {
        width: 178px 
        }
        button {
        min-width: 144px;
        }
    }

`

export default StyledHeader