interface INavItem {
  title: string;
  variant: 'transparent' | 'filled' | 'light';
  link: string;
}

export const ROUTES = {
  LOGIN: '/login',
  ROOT: '/',
  TAGS: '/tags',
};

export const API_ROUTES = {
  LOGIN: '/login',
  COOKIE_LOGIN: '/cookie-login',
  LOGOUT: '/signout',
  GET_TAGS: '/group-mapping',
  CREATE_TAG: '/tag/create',
  EDIT_TAG: '/tag/edit',
  REMOVE_TAG: '/tag/remove',
};

export const ERR_MESSAGES = {
  '500': 'Server error. ',
  FETCH_ERROR: 'Network error. Please check your connection',
  baseError: 'Something went wrong. Try again later.',
};

export const navItems: INavItem[] = [
  {
    title: 'View Claims',
    variant: 'light',
    link: ROUTES.ROOT,
  },
  {
    title: 'Manage Tags',
    variant: 'light',
    link: ROUTES.TAGS,
  },
  {
    title: 'System Notification',
    variant: 'light',
    link: ROUTES.ROOT,
  },
  {
    title: 'Suggestion',
    variant: 'light',
    link: ROUTES.ROOT,
  },
  {
    title: 'Add a business',
    variant: 'filled',
    link: ROUTES.ROOT,
  },
  {
    title: 'View Your Businesses',
    variant: 'filled',
    link: ROUTES.ROOT,
  },
];
