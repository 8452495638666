import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ROUTES } from '../shared/constants'
import { ILoginCreds, IUser } from '../shared/types/auth'

const { REACT_APP_API_BASE_URL } = process.env

export const authApi = createApi({
    reducerPath: 'authReducer',
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_API_BASE_URL,
        credentials: "include"
    }),

    endpoints: (builder) => ({
        login: builder.mutation<IUser, ILoginCreds>({
            query: (body: ILoginCreds) => ({ url: API_ROUTES.LOGIN, method: "POST", body }),
        }),
        cookieLogin: builder.mutation<IUser, {}>({
            query: () => ({ url: API_ROUTES.COOKIE_LOGIN, method: "POST", body: {} })
        }),
        logout: builder.mutation<{ status: string }, {}>({
            query: () => ({ url: API_ROUTES.LOGOUT, method: "POST", body: {} })
        })
    }),
})


export const { useLoginMutation, reducerPath } = authApi
