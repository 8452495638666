export interface IGroup {
  id: string;
  name: string;
  categories: ICategory[];
}

export interface ICategory {
  id: string;
  name: string;
  headers: IHeader[];
}

export interface IHeader {
  id: string;
  name: string;
  subcategories: ISubcategory[];
}

export enum LEVEL {
  GROUP = 'group',
  HEADER = 'header',
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory',
  TAG = 'tag',
}

export interface ISubcategory {
  id: string;
  name: string;
}

export interface ISearchItem {
  id: string;
  name: string;
  category?: { id: string; name: string };
  header?: { id: string; name: string };
  group?: { id: string; name: string };
}
