import { css } from "@emotion/react";

const styles = css`
body {
  margin: 0;
  font-family:'Poppins';
  font-size: 14px;
  line-height: 1.42;
  font-weight: 400;
}

a{
  display: block;
  text-decoration: none;
}

*, *::after, *::before {
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
}

ul, ol {
  list-style: none;
}
`

export default styles