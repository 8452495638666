import { styled, Button as MuiBtn } from "@mui/material"
import { theme } from "../../../styles/theme"

const Button = styled(MuiBtn)<{ fullWidth?: boolean }>(({ fullWidth }) => ({
    borderRadius: "36px",
    textTransform: "none",
    padding: "4px 8px",
    width: fullWidth ? "100%" : "fit-content",
    border: `1px solid ${theme.palette.common.orange}`,
    boxShadow: "none",
    fontFamily: "Poppins",
    fontWeight: 400,
    ":disabled": {
        border: `1px solid ${theme.palette.common.lightGray}`,
    }
}))

export default Button