import { NavLinkProps } from "react-router-dom"
import StyledNavLink from "./StyledNavLink"

interface IProps extends NavLinkProps {
    variant: 'transparent' | "filled" | "light"
    title: string
}

const NavigationLink = ({ title, ...rest }: IProps
) => <StyledNavLink {...rest}>{title}</StyledNavLink>

export default NavigationLink