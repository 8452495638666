import styled from "@emotion/styled";
import { Stack } from "@mui/material"

import logo from "../shared/assets/heart.svg"
import AsideLayout from "../shared/components/AsideLayout"
import NavigationLink from "../shared/components/NavigationLink";
import { navItems } from "../shared/constants";
import useAuth from "../shared/hooks/useAuth";
import Navigation from "./Navigation";

const LogoWrapper = styled.div`
  margin-bottom: 10px;
  text-align: center;
  h1 {
    font-size: 37px;
    line-height: 1.1;
    letter-spacing: -0.56px;
    font-weight: 700;
  }
`;

const HomePage = () => {
    const { user } = useAuth()

    return <AsideLayout>
        <Stack alignItems="center" >
            <LogoWrapper>
                <img src={logo} alt="logo" height="82" />
                <h1>Welcome {user?.firstName}</h1>
            </LogoWrapper>
            <Navigation>
                {navItems.map(({ title, variant, link }) =>
                    <NavigationLink key={title} title={title} to={link} variant={variant} />
                )}
            </Navigation>
        </Stack>
    </AsideLayout>
}
export default HomePage