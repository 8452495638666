import styled from '@emotion/styled';
import theme from '../../styles/theme';

const SectionHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 18px;
  line-height: 1.1;
  letter-spacing: -0.26px;
  color: ${theme.palette.common.orange};
`;
export default SectionHeader;
