import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { CircularProgress, InputAdornment, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getSelectedGroup, getTags } from '../redux/tags/tagsSelectors';
import { setSelectedGroup } from '../redux/tags/tagsSlice';
import { tagsApi } from '../services/tagsApi';
import { ReactComponent as SearchIcon } from '../shared/assets/search.svg';
import Field from '../shared/components/Field';
import PageContainer from '../shared/components/PageContainer/PageContainer';
import PageHead from '../shared/components/PageHead';
import { LEVEL } from '../shared/types/tags';
import theme from '../styles/theme';
import TagsSearch from './SearchTags/TagsSearch';
import CategorySelect from './Tags/CategorySelect';
import SectionHeader from './Tags/SectionHeader';
import { Buttons } from './TagsButtons/Buttons';
import GroupButton from './TagsButtons/GroupButton';
import TagsDialog from './TagsDialog/TagsDialog';
import { IOperation } from './types';

const TagsWrapper = styled.div`
  flex: 1;

  @media screen and (min-width: 700px) {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
const GroupControls = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.palette.common.lightGray};
  width: 100%;
  padding-left: 20px;
`;
const GroupsWrapper = styled.div`
  margin: 0 auto 60px;
  padding-top: 14px;

  p {
    margin-bottom: 18px;
  }

  @media screen and (min-width: 700px) {
    max-width: 274px;
    padding-right: 64px;
    margin: 0;
    border-right: 1px solid ${theme.palette.common.lightGray};
  }
`;

const Container = styled.div`
  padding: 16px;

  @media screen and (min-width: 700px) {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 46px 80px;
  }
`;

const TagsPage = () => {
  const { isLoading } = tagsApi.useFetchTagsQuery('all');
  const currentGroupId = useAppSelector(getSelectedGroup);
  const groups = useAppSelector(getTags);
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState('');
  const [operation, setOperation] = useState<IOperation>({
    type: null,
    data: { title: '' },
  });

  const currentGroup = useMemo(() => {
    if (!groups || !currentGroupId) return null;
    const group = groups.find((el) => el.id === currentGroupId);
    if (!group) return null;
    return {
      ...group,
      categories: [...group.categories].sort((cat1, cat2) =>
        cat1.name.localeCompare(cat2.name),
      ),
    };
  }, [currentGroupId, groups]);

  const closeModal = () => {
    setOperation({ type: null, data: { title: '' } });
  };

  const resetGroup = () => {
    dispatch(setSelectedGroup(null));
  };

  const handleGroupClick = (id: string) => {
    if (currentGroupId === id) {
      resetGroup();
    } else {
      dispatch(setSelectedGroup(id));
    }
  };

  // const createGroup = () =>
  //   setOperation({
  //     data: {
  //       level: LEVEL.GROUP,
  //       title: 'Create a new group',
  //     },
  //     type: 'add',
  //   });

  return (
    <PageContainer>
      <PageHead>
        <h1>Tag Manager</h1>
        <Field
          id="input-with-icon-textfield"
          placeholder="Search all tags"
          value={searchInput}
          onChange={({ target }) => setSearchInput(target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon height={16} width={16} />
              </InputAdornment>
            ),
          }}
        />
      </PageHead>
      <Container>
        <GroupsWrapper>
          <SectionHeader>All Groups</SectionHeader>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <Stack gap="10px">
            {isLoading ? (
              <CircularProgress />
            ) : (
              groups?.map(({ name, id }) => (
                <GroupButton
                  fullWidth
                  onClick={() => handleGroupClick(id)}
                  selected={currentGroupId === id}
                  key={id}
                >
                  {name}
                </GroupButton>
              ))
            )}
            {/* <GroupButton
              fullWidth
              transparent="true"
              endIcon={<AddCircleIcon color="primary" />}
              onClick={createGroup}
            >
              Add New Group
            </GroupButton> */}
          </Stack>
        </GroupsWrapper>
        <TagsWrapper>
          {searchInput ? (
            <TagsSearch
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              setOperation={setOperation}
            />
          ) : (
            <>
              <GroupControls>
                <SectionHeader
                  style={{
                    color: theme.palette.common.black,
                    margin: '0 auto 0 0',
                  }}
                >
                  {currentGroup
                    ? currentGroup.name
                    : groups?.length === 0
                    ? 'Create A Group'
                    : 'Select A Group'}
                </SectionHeader>

                <Buttons
                  name={currentGroup?.name || ''}
                  id={currentGroup?.id || ''}
                  label
                  level={LEVEL.GROUP}
                  disabled={!currentGroupId}
                  openModal={setOperation}
                  // addBtn={
                  //   <TagsButton operation="Add" label onClick={createGroup} />
                  // }
                />
              </GroupControls>
              {currentGroup && (
                <CategorySelect
                  currentGroup={currentGroup}
                  openModal={setOperation}
                />
              )}
            </>
          )}
          <TagsDialog
            isOpen={Boolean(operation.type)}
            operation={operation}
            handleClose={closeModal}
          />
        </TagsWrapper>
      </Container>
    </PageContainer>
  );
};

export default TagsPage;
