import { createSlice } from '@reduxjs/toolkit';
import { tagsApi } from '../../services/tagsApi';
import { IGroup } from '../../shared/types/tags';

type TagsState = {
  tags: IGroup[] | null;
  selectedGroup: string | null;
  selectedCategory: string | null;
  selectedHeader: string | null;
  foundSubcategory: string | null;
};

const initialState: TagsState = {
  tags: null,
  selectedGroup: null,
  selectedCategory: null,
  selectedHeader: null,
  foundSubcategory: null,
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setSelectedGroup: (state, { payload }) => {
      state.selectedGroup = payload;
      state.selectedHeader = null;
      state.selectedCategory = null;
    },
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload;
      state.selectedHeader = null;
    },
    setSelectedHeader: (state, { payload }) => {
      state.selectedHeader = payload;
    },
    resetTags: (state) => {
      state.selectedHeader = null;
      state.selectedGroup = null;
      state.selectedCategory = null;
    },
    setFoundSubcategory: (state, { payload }) => {
      state.foundSubcategory = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      tagsApi.endpoints.fetchTags.matchFulfilled,
      (state, { payload }) => {
        state.tags = payload;
      },
    );
  },
});

export const {
  setSelectedGroup,
  setSelectedCategory,
  setSelectedHeader,
  setFoundSubcategory,
} = tagsSlice.actions;

export default tagsSlice;
