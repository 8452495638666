import React from "react"
import styled from "@emotion/styled"
import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem } from "@mui/material"
import { NavLink } from "react-router-dom"
import { authApi } from "../../../services/authApi"
import theme from "../../../styles/theme"
import { ROUTES } from "../../constants"
import AppButton from "../Button"

const StyledMenu = styled(Menu)`
    margin-top: 30px;
    ul{
       background-color: ${theme.palette.common.darkPurple}; 
    }
    a{
    color: ${theme.palette.common.white};
    
    :focus{
        color: ${theme.palette.common.white};
    }}
`

const ButtonIcn = styled(IconButton)`
    min-width: 18px !important;

    & > svg{
        width: 24px;
        height: 24px;
    }
`

export const MobileMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => open ?
        setAnchorEl(null) :
        setAnchorEl(event.currentTarget)

    const handleClose = () => {
        setAnchorEl(null)
    };

    const [logout, { isLoading }] = authApi.useLogoutMutation()

    return <div>

        <ButtonIcn
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{ color: theme.palette.common.white }}
        >
            {open ? <ClearIcon color="inherit" /> : <MenuIcon color="inherit" />}
        </ButtonIcn>
        <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem><NavLink to={ROUTES.ROOT}>Home</NavLink></MenuItem>
            <MenuItem><NavLink to={ROUTES.ROOT}>My Business</NavLink></MenuItem>
            <MenuItem><AppButton onClick={logout} fullWidth disabled={isLoading}>Logout</AppButton></MenuItem>
        </StyledMenu>
    </div>
}

export default MobileMenu