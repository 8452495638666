import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const Field = styled(TextField)`
    width: 100%;
    max-width: 290px;
    font-size: 14px;
    line-height:1.5;
    letter-spacing: 0px;
    
    fieldset {  
        border: 0;
    }

    input {
        padding: 8px;       
    }
`
export default Field