import styled from "@emotion/styled"
import theme from "../../../styles/theme"

const PageHead = styled.div`
    background: linear-gradient(100deg, rgba(238, 51, 51, 0.09) 0%, rgba(26, 77, 255, 0.1) 100%);
    padding: 26px;
    text-align: center;

    h1{
        margin-bottom: 20px;
        color: ${theme.palette.common.darkPurple};
        font-size: 26px;
        line-height: 1.05;
        font-weight: 700;
    }   

    @media screen and (min-width: 700px) {
        padding: 26px 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1{
            margin: 0;
        }
    }
`

export default PageHead