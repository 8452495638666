import styled from "@emotion/styled"
import theme from "../../../styles/theme"
import img from "../../assets/auth-aside.jpg"

const AsideWrapper = styled.aside`
    display: none;

    @media screen and (min-width: 1000px){
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 29px;
        color: ${theme.palette.common.white};
        line-height: 1.86;
        background-color: ${theme.palette.common.purple};
        background:linear-gradient(rgba(53, 12, 69, 0.514) 0%, rgba(53, 12, 69, 0.514) 100%),
                url(${img}) center / cover no-repeat;
            
        img {
            width: 65%;
            max-width: 500px;
            margin-bottom: 22px;
        }
    }
`

export default AsideWrapper