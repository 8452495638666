import React from 'react';
import { createTheme } from '@mui/material';

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    purple: React.CSSProperties['color'];
    darkPurple: React.CSSProperties['color'];
    orange: React.CSSProperties['color'];
    darkOrange: React.CSSProperties['color'];
    beige: React.CSSProperties['color'];
    beige1: React.CSSProperties['color'];
    darkBeige: React.CSSProperties['color'];
    text: React.CSSProperties['color'];
    text2: React.CSSProperties['color'];
    lightGray: React.CSSProperties['color'];
    gray: React.CSSProperties['color'];
    green: React.CSSProperties['color'];
  }
}

export const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained"
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiCircularProgress: {
      defaultProps: {
        color: "secondary"
      },
      styleOverrides: {
        root: {
          marginRight: "auto",
          marginLeft: "auto"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "28px",
          backgroundColor: "#fff",

          input: {
            padding: "10px 20px"
          },

        }
      }
    }
  },
  palette: {
    common: {
      white: '#ffffff',
      black: '#482655',
      purple: "#482655",
      darkPurple: "#482655",
      orange: "#F15A24",
      darkOrange: "#E93B02",
      text: "#595959",
      text2: "#050628",
      beige: "#F7F1EA",
      beige1: "#FEEEE8",
      darkBeige: "#FFE0D4",
      lightGray: "#D9D9E8",
      gray: "#9898A5",
      green: "#00C48B"
    },
    primary: {
      light: '#FA6D3A',
      main: '#F15A24',
      dark: '#E93B02',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#482655',
      contrastText: '#ffffff',
    },
  },
});

export default theme;
