import {
  configureStore, combineReducers, Middleware, MiddlewareAPI, isRejectedWithValue,
} from '@reduxjs/toolkit';
import { authApi } from '../services/authApi';
import { tagsApi } from '../services/tagsApi';
import authSlice, { logoutAction } from './auth/authSlice';
import tagsSlice from './tags/tagsSlice';

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  auth: authSlice.reducer,
  tags: tagsSlice.reducer
});

export const errorInterceptor: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401) {
        api.dispatch(logoutAction)
      }
    }
    return next(action)
  }

export const setupStore = () => configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddlware) => getDefaultMiddlware().concat(authApi.middleware, tagsApi.middleware, errorInterceptor)
});

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<typeof rootReducer>;
