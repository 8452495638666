import styled from '@emotion/styled';
import { theme } from '../../styles/theme';

const SelectContainer = styled.div`
  padding-top: 18px;
  h3,
  h4 {
    padding-left: 20px;
    margin-bottom: 10px;
    margin-right: auto;
    color: ${theme.palette.common.orange};
  }

  @media screen and (min-width: 700px) {
    width: 95%;
    margin-left: auto;
  }
`;
export default SelectContainer;
