import { useNavigate, NavLink } from 'react-router-dom'
import { authApi } from '../../../services/authApi'
import { ReactComponent as Logo } from "../../assets/logo.svg"
import { ROUTES } from '../../constants'
import useAuth from '../../hooks/useAuth'
import useWindowDimensions from '../../hooks/useWindowDementions'
import Button from "../Button/Button"
import NavigationLink from '../NavigationLink'
import MobileMenu from './MobileMenu'
import StyledHeader from './StyledHeader'

const Header = () => {
    const navigate = useNavigate()
    const { isAuth } = useAuth()
    const [logout, { isLoading }] = authApi.useLogoutMutation()
    const { width } = useWindowDimensions()

    const handleLogin = () => navigate(ROUTES.LOGIN)

    return <StyledHeader>
        <NavLink to={ROUTES.ROOT}>
            <Logo />
        </NavLink>
        {
            !isAuth ? <Button onClick={handleLogin}>Log in</Button>
                : width >= 800 ?
                    <nav>
                        <NavLink to={ROUTES.ROOT}>Home</NavLink>
                        <NavigationLink title='My Business' to={ROUTES.ROOT} variant='transparent' />
                        <Button onClick={logout} disabled={isLoading}>Logout</Button>
                    </nav>
                    : <MobileMenu />
        }
    </StyledHeader>
}

export default Header