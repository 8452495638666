import { useEffect } from "react"
import { authApi } from "../../services/authApi"

const useInitialAuth = () => {
    const [cookieLogin, { isSuccess, isError }] = authApi.useCookieLoginMutation()

    const isLoading = !isSuccess && !isError

    useEffect(() => {
        cookieLogin({})
    }, [cookieLogin])

    return { isLoading }
}

export default useInitialAuth