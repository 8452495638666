import { FC, PropsWithChildren } from "react"
import styled from "@emotion/styled"
import { theme } from "../../../styles/theme"
import heart from "../../assets/light-heart.svg"
import logo from "../../assets/logo.svg"
import AsideWrapper from "./AsideWrapper"

export const AsideLayoutWrapper = styled.div`  
    min-height: calc(100vh - 160px);

    @media screen and (min-width: 1000px){
        display: flex;
}
`

const ContentWrapper = styled.div`
position: relative;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.common.beige};
    min-height: calc(100vh - 160px);
    overflow: hidden;

    @media screen and (min-width: 1000px){
        width: 40%;
        background: right -260px bottom -250px/ 520px no-repeat url(${heart});
    }
`

const AsideLayout: FC<PropsWithChildren> = ({ children }) => <AsideLayoutWrapper>
    <AsideWrapper>
        <img src={logo} alt="Logo" />
        <p>Search, Fave, Share</p>
    </AsideWrapper>
    <ContentWrapper>
        {children}
    </ContentWrapper>
</AsideLayoutWrapper>

export default AsideLayout