import { FC } from 'react';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Close';
import { Button, ButtonProps } from '@mui/material';
import { ReactComponent as EditIcon } from '../../shared/assets/edit.svg';
import theme from '../../styles/theme';

type Operation = 'Add' | 'Remove' | 'Edit';

interface IProps extends ButtonProps {
  operation: Operation;
  label?: boolean;
}

const StyledButton = styled(Button)<{ bg: string }>`
  position: relative;
  text-transform: none;
  color: ${theme.palette.common.gray};
  min-width: fit-content;
  padding: 8px;

  .label {
    position: absolute;
    top: -80%;
  }
  &:hover {
    background-color: transparent;
    svg,
    div {
      transition:
        all 0.2s ease-in-out 0s,
        color 0.15s ease-in-out 0s;
      scale: 1.1;
    }
  }
  div {
    display: flex;
    background-color: ${({ bg }) => bg};
    height: 18px;
    width: 18px;
    border-radius: 50%;
    min-width: none;

    svg {
      width: 70%;
      height: 70%;
      margin: auto;
      color: ${theme.palette.common.white};
    }
  }
`;
const getColor = (type: Operation, disabled: boolean | undefined): string => {
  if (disabled) return theme.palette.common.lightGray!;
  if (type === 'Add') return theme.palette.common.green!;
  if (type === 'Remove') return theme.palette.common.gray!;
  return theme.palette.common.orange!;
};

const TagsButton: FC<IProps> = ({
  disabled,
  operation,
  label = false,
  ...rest
}) => (
  <StyledButton
    bg={getColor(operation, disabled)}
    disabled={disabled}
    variant="text"
    {...rest}
  >
    {label && <span className="label">{operation}</span>}
    {operation === 'Add' && (
      <div>
        {' '}
        <AddIcon />
      </div>
    )}
    {operation === 'Remove' && (
      <div>
        {' '}
        <RemoveIcon />
      </div>
    )}
    {operation === 'Edit' && <EditIcon fill={getColor(operation, disabled)} />}
  </StyledButton>
);

export default TagsButton;
