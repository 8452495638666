import { createSlice } from '@reduxjs/toolkit'
import { authApi } from '../../services/authApi'
import { IUser } from '../../shared/types/auth';

type AuthState = {
    user: null | IUser
    isAuth: boolean
}

const initialState: AuthState = { user: null, isAuth: false }

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.isAuth = false
            state.user = null
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, { payload }) => {
                state.user = payload;
                state.isAuth = true;
            }
        )
            .addMatcher(
                authApi.endpoints.cookieLogin.matchFulfilled,
                (state, { payload }) => {
                    state.user = payload;
                    state.isAuth = true;
                })
            .addMatcher(authApi.endpoints.logout.matchFulfilled,
                (state) => {
                    state.user = null
                    state.isAuth = false
                }
            )
    },
})

export const logoutAction = authSlice.actions.logout
export default authSlice