import React from 'react';
import { FallbackProps } from 'react-error-boundary';

function ErrorFallback({ error }: FallbackProps) {
  return (
    <div>
      <p>Something went wrong:</p>
      <span>{error.message}</span>
    </div>
  );
}

export default ErrorFallback;
