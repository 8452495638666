import { CircularProgress, Stack } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes } from 'react-router-dom';
import HomePage from '../home';
import LoginPage from '../login';
import { ROUTES } from '../shared/constants';
import TagsPage from '../tags';
import AppShell from './AppShell';
import AuthGuard from './AuthGuard';
import ErrorFallback from './ErrorFallback';
import useInitialAuth from './hooks/useInitialAuth';

function App() {
  const { isLoading } = useInitialAuth()
  return (
    !isLoading ?
      <ErrorBoundary fallbackRender={ErrorFallback}>
        <Routes>
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />

          {/* Protected routes */}
          <Route element={<AuthGuard><AppShell /></AuthGuard>}>
            <Route index element={<HomePage />} />

            <Route path={ROUTES.TAGS} element={<TagsPage />} />
          </Route>
          <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
        </Routes>
      </ErrorBoundary>
      :
      <Stack alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
      </Stack>
  );
}

export default App;
