import { FC, useEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getFoundSubcategory,
  getSelectedHeader,
} from '../../redux/tags/tagsSelectors';
import {
  setFoundSubcategory,
  setSelectedHeader,
} from '../../redux/tags/tagsSlice';
import { ICategory, LEVEL } from '../../shared/types/tags';
import { Buttons, ButtonsWrapper } from '../TagsButtons/Buttons';
import TagsButton from '../TagsButtons/TagsButton';
import { IOperation } from '../types';
import SelectContainer from './SelectContainer';
import TagsList from './TagsList';

interface IProps {
  category: ICategory;
  openModal: (operation: IOperation) => void;
}

const Info = styled.p`
  padding: 0 20px;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;

  .MuiAccordionSummary-content {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Mui-expanded {
    margin: 0 !important;
    min-height: fit-content !important;
  }

  .MuiAccordionDetails-root {
    padding: 0 0 20px;
  }
`;

const Headers: FC<IProps> = ({ category, openModal }) => {
  const selectedHeaderId = useAppSelector(getSelectedHeader);
  const dispatch = useAppDispatch();
  const headerRef = useRef<HTMLLIElement | null>(null);
  const subCatRef = useRef<HTMLLIElement | null>(null);
  const foundSubcategory = useAppSelector(getFoundSubcategory);

  useEffect(() => {
    if (subCatRef.current) {
      subCatRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setTimeout(() => dispatch(setFoundSubcategory(null)), 2000);
    }
  }, [foundSubcategory, dispatch]);

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [headerRef]);

  const selectedHeader = useMemo(() => {
    if (!selectedHeaderId) return null;
    const header = category?.headers?.find((el) => el.id === selectedHeaderId);
    if (!header) return null;
    return {
      ...header,
      subcategories: [...header.subcategories].sort((sc1, sc2) =>
        sc1.name.localeCompare(sc2.name),
      ),
    };
  }, [category?.headers, selectedHeaderId]);

  const handleHeaderClick = (id: string) => {
    if (id === selectedHeader?.id) {
      dispatch(setSelectedHeader(null));
    } else {
      dispatch(setSelectedHeader(id));
    }
  };
  return (
    <SelectContainer>
      <Stack flexDirection="row" alignItems="center">
        <h4>Header</h4>
        <ButtonsWrapper>
          <TagsButton
            operation="Add"
            onClick={() =>
              openModal({
                type: 'add',
                data: {
                  title: 'Create a new header',
                  parentId: category?.id,
                  level: LEVEL.HEADER,
                },
              })
            }
          />
        </ButtonsWrapper>
      </Stack>
      {category?.headers.length === 0 ? (
        <Info>There are no headers in category {category.name}</Info>
      ) : (
        <TagsList>
          {category.headers.map(({ id, name, subcategories }) => (
            <li key={id} ref={id === selectedHeaderId ? headerRef : null}>
              <StyledAccordion expanded={id === selectedHeaderId}>
                <AccordionSummary
                  className={`item ${
                    selectedHeaderId === id ? 'selected' : ''
                  }`}
                  onClick={() => handleHeaderClick(id)}
                >
                  {name}
                  <Buttons
                    name={name}
                    id={id}
                    disabled={false}
                    level={LEVEL.HEADER}
                    openModal={openModal}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <SelectContainer>
                    <Stack flexDirection="row" alignItems="center">
                      <h4>Subcategory</h4>
                      <ButtonsWrapper>
                        <TagsButton
                          operation="Add"
                          onClick={() => {
                            openModal({
                              type: 'add',
                              data: {
                                title: 'Create a new subcategory',
                                parentId: selectedHeader?.id,
                                level: LEVEL.SUBCATEGORY,
                              },
                            });
                          }}
                        />
                      </ButtonsWrapper>
                    </Stack>
                    {subcategories.length === 0 ? (
                      <Info>There are no subcategories in header {name}</Info>
                    ) : (
                      <TagsList>
                        {subcategories.map(({ id: subId, name: subName }) => (
                          <li
                            key={subId}
                            ref={subId === foundSubcategory ? subCatRef : null}
                            className={`item ${
                              foundSubcategory === subId ? 'selected' : ''
                            }`}
                          >
                            {subName}
                            <Buttons
                              name={subName}
                              id={subId}
                              disabled={false}
                              level={LEVEL.SUBCATEGORY}
                              openModal={openModal}
                            />
                          </li>
                        ))}
                      </TagsList>
                    )}
                  </SelectContainer>
                </AccordionDetails>
              </StyledAccordion>
            </li>
          ))}
        </TagsList>
      )}
    </SelectContainer>
  );
};

export default Headers;
