import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '../../shared/components/Button';
import theme from '../../styles/theme';

const GroupButton = styled(Button)<{
  selected?: boolean;
  transparent?: string;
}>`
  position: relative;
  border: none;
  &:hover {
    box-shadow: none;
  }

  ${({ selected }) =>
    selected === false &&
    css`
      background-color: ${theme.palette.common.beige1};
      color: ${theme.palette.common.orange};
      border: ${theme.palette.common.beige1};
      &:hover {
        background-color: ${theme.palette.common.darkBeige};
      }
    `}

  ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent;
      color: ${theme.palette.common.orange};
      border: 1px solid ${theme.palette.common.orange};
      &:hover {
        background-color: ${theme.palette.common.beige1};
      }
    `}
svg {
    margin: 0;
    position: absolute;
    right: 20px;
    bottom: 10px;
    height: 12px;
  }
`;

export default GroupButton;
