import styled from "@emotion/styled"

const Navigation = styled.nav`
display: flex;
flex-direction: column;
gap: 10px;
width: 290px;
align-items: stretch;
text-align: center;
`

export default Navigation