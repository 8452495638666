import { FC, useMemo } from 'react';
import { Breadcrumbs } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTags } from '../../redux/tags/tagsSelectors';
import {
  setSelectedGroup,
  setSelectedCategory,
  setSelectedHeader,
  setFoundSubcategory,
} from '../../redux/tags/tagsSlice';
import { ISearchItem, LEVEL } from '../../shared/types/tags';
import SectionHeader from '../Tags/SectionHeader';
import SelectContainer from '../Tags/SelectContainer';
import TagsList from '../Tags/TagsList';
import { Buttons } from '../TagsButtons/Buttons';
import { IOperation } from '../types';
import searchTags from './utils/searchTags';

interface IProps {
  searchInput: string;
  setSearchInput: (input: string) => void;
  setOperation: (operation: IOperation) => void;
}

const TagsSearch: FC<IProps> = ({
  searchInput,
  setOperation,
  setSearchInput,
}) => {
  const groups = useAppSelector(getTags);
  const dispatch = useAppDispatch();
  const searchResults = useMemo(() => {
    if (!groups || !searchInput || searchInput.length < 3) return null;
    return searchTags(searchInput, groups);
  }, [searchInput, groups]);

  const handleSearchResultClick = (result: ISearchItem) => {
    const { id, category, header, group } = result;

    dispatch(setSelectedGroup(group?.id ?? id));
    if (group) dispatch(setSelectedCategory(category?.id ?? id));
    if (category) dispatch(setSelectedHeader(header?.id ?? id));
    if (header) dispatch(setFoundSubcategory(id));

    setSearchInput('');
  };

  return (
    <SelectContainer>
      <SectionHeader>Search Results</SectionHeader>
      {searchInput.length < 3 && <span>Please enter at least 3 symbols</span>}
      {searchResults && searchResults.length > 0 && (
        <TagsList>
          {searchResults.map((res) => {
            const { name, id, group, category, header } = res;

            return (
              <li
                key={id}
                className="item pointer"
                onClick={() => handleSearchResultClick(res)}
              >
                <Breadcrumbs aria-label="breadcrumb">
                  {group && <p>{group.name}</p>}
                  {category && <p>{category.name}</p>}
                  {header && <p>{header.name}</p>}
                  <p>{name}</p>
                </Breadcrumbs>
                <Buttons
                  id={id}
                  name={name}
                  openModal={setOperation}
                  level={LEVEL.TAG}
                  disabled={false}
                />
              </li>
            );
          })}
        </TagsList>
      )}
      {searchResults?.length === 0 && searchInput.length > 2 && 'No tags found'}
    </SelectContainer>
  );
};
export default TagsSearch;
