import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { NavLink } from "react-router-dom"
import theme from "../../../styles/theme"

const StyledNavLink = styled(NavLink) <{ variant: 'transparent' | "filled" | "light" }>`
    display: block;
    border: 1px solid ${theme.palette.common.white};
    color: ${theme.palette.common.white};
    border-radius: 36px;
    text-decoration: none;
    padding: 4px 20px;
    font-size: 13px;
    line-height: 1.5;
    transition: background-color 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;
    

    ${({ variant }) => {
        if (variant === "transparent") {
            return css`
            background-color: transparent;
        `
        }
        if (variant === "filled") {
            return css`
            background-color: ${theme.palette.common.orange};
            &:hover, &:focus {
                background-color: ${theme.palette.common.darkOrange};
            }
        `
        }
        return css`
        background-color: ${theme.palette.common.beige1};
        color:  ${theme.palette.common.orange};
        border-color:  ${theme.palette.common.orange};
        &:hover, &:focus {
                background-color: ${theme.palette.common.darkBeige};
            }

    `
    }}

`

export default StyledNavLink