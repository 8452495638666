import { PropsWithChildren, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../shared/constants';
import useAuth from '../shared/hooks/useAuth';

interface IProps extends PropsWithChildren {
  children: ReactElement
}

function AuthGuard({ children }: IProps) {
  const { isAuth } = useAuth();
  return isAuth ? children : <Navigate to={ROUTES.LOGIN} />;
}

export default AuthGuard;
