import { ERR_MESSAGES } from "../constants"

export interface IError {
    status: string | number
    data: {
        status: string
    }
}

export const getErrorMessage = (serverMessageCodes: (string | number)[], { status, data }: IError) => {
    if (serverMessageCodes.includes(status)) {
        return data.status
    }
    if (ERR_MESSAGES[status as keyof typeof ERR_MESSAGES]) {
        return ERR_MESSAGES[status as keyof typeof ERR_MESSAGES]
    }
    return ERR_MESSAGES.baseError
}