import { useEffect } from "react"
import styled from "@emotion/styled"
import { Alert, CircularProgress } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { authApi } from "../services/authApi"
import logo from '../shared/assets/heart.svg'
import AuthLayout from "../shared/components/AsideLayout"
import Button from "../shared/components/Button"
import Footer from "../shared/components/Footer"
import Header from "../shared/components/Header"
import Input from "../shared/components/Input"
import { ROUTES } from "../shared/constants"
import useAuth from "../shared/hooks/useAuth"
import { ILoginCreds } from "../shared/types/auth"
import { IError, getErrorMessage } from "../shared/utils/getErrorMessage"

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  width: 100%;

  form {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  button {
      max-width: 180px;
      padding: 4px 0;
      margin: 0 auto;
    }
  input {
    width: 100%;
  }
`;

const LogoWrapper = styled.div`
  max-width: 250px;
  text-align: center;
  h1 {
    font-size: 32px;
    font-weight: 700;
  }
`;


const defaultValues = {
  email: "",
  password: ""
}

export function LoginPage() {
  const navigate = useNavigate()
  const auth = useAuth()
  const methods = useForm<ILoginCreds>({
    reValidateMode: "onChange",
    defaultValues
  })

  const { handleSubmit } = methods

  const [handleLogin, { error: loginError, isLoading }] = authApi.useLoginMutation()

  useEffect(() => {
    if (auth.isAuth) {
      navigate(ROUTES.ROOT)
    }
  }, [auth, navigate])

  return <>
    <Header />
    <AuthLayout>
      <FormWrapper>
        <LogoWrapper>
          <img src={logo} alt="logo" height="82" />
          <h1>Login</h1>
        </LogoWrapper>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleLogin)}>
            <Input name="email" label="Email" />

            <Input name="password" label="Password" type="password" />

            {loginError && <Alert severity="error" variant="outlined" sx={{ marginBottom: 2, padding: "0 4px" }}>{getErrorMessage([401, 400], loginError as IError)}</Alert>}
            {isLoading ? <CircularProgress /> : <Button type="submit" disabled={isLoading} fullWidth>Log in</Button>}
          </form>
        </FormProvider>
      </FormWrapper>
    </AuthLayout >
    <Footer />
  </>

}

export default LoginPage