import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSelectedCategory } from '../../redux/tags/tagsSelectors';
import { setSelectedCategory } from '../../redux/tags/tagsSlice';
import { IGroup, LEVEL } from '../../shared/types/tags';
import theme from '../../styles/theme';
import { Buttons } from '../TagsButtons/Buttons';
import TagsButton from '../TagsButtons/TagsButton';
import { IOperation } from '../types';
import Headers from './Headers';
import SelectContainer from './SelectContainer';

const Select = styled.div`
  width: 100%;
  border-radius: 38px;
  transition:
    height 0.15s ease-in-out 0s,
    color 0.15s ease-in-out 0s;

  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border: 1px solid ${theme.palette.common.lightGray};
    border-radius: 36px;
    cursor: pointer;

    .MuiSvgIcon-root {
      height: 20px;
    }
  }

  ul {
    padding: 12px 0;
    transition:
      height 0.15s ease-in-out 0s,
      color 0.15s ease-in-out 0s;

    border-bottom: 1px solid ${theme.palette.common.lightGray};
    li {
      padding: 8px 20px;
      border-radius: 10px;
      cursor: pointer;
      :hover {
        background-color: ${theme.palette.common.beige1};
      }
    }
  }
`;

const CategorySelect = ({
  currentGroup,
  openModal,
}: {
  currentGroup: IGroup;
  openModal: (operation: IOperation) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedCategoryId = useAppSelector(getSelectedCategory);
  const dispatch = useAppDispatch();

  const selectedCategory = useMemo(() => {
    if (!selectedCategoryId) return null;
    const category = currentGroup.categories.find(
      (el) => el.id === selectedCategoryId,
    );
    if (!category) return null;
    return {
      ...category,
      headers: [...category.headers].sort((header1, header2) =>
        header1.name.localeCompare(header2.name),
      ),
    };
  }, [selectedCategoryId, currentGroup.categories]);

  useEffect(() => {
    setIsOpen(false);
  }, [currentGroup.id]);

  const handleCategoryClick = (id: string) => {
    dispatch(setSelectedCategory(id));
    setIsOpen(false);
  };

  const createCategory = () =>
    openModal({
      type: 'add',
      data: {
        title: 'Create a new category',
        parentId: currentGroup.id,
        level: LEVEL.CATEGORY,
      },
    });

  return (
    <SelectContainer>
      <Stack>
        <h3>Category</h3>
        <Stack flexDirection="row" alignItems="flex-start">
          <Select>
            <div className="input" onClick={() => setIsOpen((prev) => !prev)}>
              <span>
                {selectedCategory
                  ? selectedCategory.name
                  : currentGroup.categories.length
                  ? 'Select a category'
                  : 'No categories available'}
              </span>
              {isOpen ? <UpIcon /> : <DownIcon />}
            </div>

            <Collapse in={isOpen}>
              {currentGroup.categories.length > 0 && (
                <ul>
                  {currentGroup.categories.map(({ id, name }) => (
                    <li key={id} onClick={() => handleCategoryClick(id)}>
                      {name}
                    </li>
                  ))}
                </ul>
              )}
            </Collapse>
          </Select>
          <Buttons
            name={selectedCategory?.name || ''}
            id={selectedCategory?.id || ''}
            disabled={!selectedCategory}
            level={LEVEL.CATEGORY}
            openModal={openModal}
            addBtn={<TagsButton operation="Add" onClick={createCategory} />}
          />
        </Stack>
      </Stack>

      {!isOpen && selectedCategory && (
        <Headers category={selectedCategory} openModal={openModal} />
      )}
    </SelectContainer>
  );
};

export default CategorySelect;
