import styled from '@emotion/styled';
import theme from '../../styles/theme';

const TagsList = styled.ul`
  width: 100%;

  & > li:not(:last-child) {
    margin-bottom: 8px;
  }
  .pointer {
    &:hover {
      cursor: pointer;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0 6px 26px;
    border: 1px solid ${theme.palette.common.lightGray};
    border-radius: 12px;
    transition:
      all 0.15s ease-in-out 0s,
      color 0.15s ease-in-out 0s;

    &.selected,
    &:hover {
      background-color: ${theme.palette.common.beige1};
      border-color: ${theme.palette.common.beige1};
      color: ${theme.palette.common.orange};
    }
  }
`;

export default TagsList;
