import {
  ICategory,
  IHeader,
  ISearchItem,
  ISubcategory,
} from '../../../shared/types/tags';

interface ITag {
  id: string;
  name: string;
  categories?: ICategory[];
  headers?: IHeader[];
  subcategories?: ISubcategory[];
}

function searchTags(searchInput: string, elements: ITag[], parentIds = {}) {
  const results: ISearchItem[] = [];

  elements.forEach((element) => {
    const { id, name, categories, headers, subcategories } = element;

    if (element.name.toLowerCase().includes(searchInput.toLowerCase())) {
      results.push({ ...parentIds, name, id });
    }

    if (categories) {
      results.push(
        ...searchTags(searchInput, categories, {
          ...parentIds,
          group: { id, name },
        }),
      );
    }
    if (headers) {
      results.push(
        ...searchTags(searchInput, headers, {
          ...parentIds,
          category: { id, name },
        }),
      );
    }
    if (subcategories) {
      results.push(
        ...searchTags(searchInput, subcategories, {
          ...parentIds,
          header: { id, name },
        }),
      );
    }
  });

  return results;
}

export default searchTags;
