import { Outlet } from 'react-router-dom';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';

function AppShell() {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default AppShell;
