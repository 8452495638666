import styled from "@emotion/styled"
import theme from "../../../styles/theme"

const StyledFooter = styled.footer`
    height: 80px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.common.purple};
    padding: 0 30px;

    a {
        text-decoration: none;
        color: inherit;
    }

    @media screen and (min-width: 480px) {
        padding: 0 80px;
    }
`

const Footer = () => {
    const currentYear = new Date().getFullYear()
    return <StyledFooter>
        <span>© {currentYear}. Fave Page. All Rights Reserved.</span>
        <a href="/">Privacy Policy</a>
    </StyledFooter>
}

export default Footer