import { RootState } from '../store';

export const getTags = (state: RootState) => state.tags.tags;

export const getSelectedGroup = (state: RootState) => state.tags.selectedGroup;

export const getSelectedCategory = (state: RootState) =>
  state.tags.selectedCategory;

export const getSelectedHeader = (state: RootState) =>
  state.tags.selectedHeader;

export const getFoundSubcategory = (state: RootState) =>
  state.tags.foundSubcategory;
