import { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { LEVEL } from '../../shared/types/tags';
import { IOperation } from '../types';
import TagsButton from './TagsButton';

interface IProps {
  name: string;
  id: string;
  label?: boolean;
  level: LEVEL;
  disabled: boolean;
  openModal: (operation: IOperation) => void;
  addBtn?: ReactElement;
}

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  height: 45px;
  padding: 0 14px;
  min-width: 112px;
`;

export const Buttons: FC<IProps> = ({
  disabled,
  label = false,
  level,
  name,
  id,
  openModal,
  addBtn,
}) => (
  <ButtonsWrapper>
    {addBtn}
    {/* <TagsButton
      operation="Remove"
      label={label}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        openModal({
          data: {
            id,
            title: `Do you want to remove ${level} ${name}?`,
          },
          type: 'remove',
        });
      }}
    /> */}
    <TagsButton
      operation="Edit"
      sx={{ marginLeft: 'auto' }}
      onClick={(e) => {
        e.stopPropagation();
        openModal({
          data: {
            id,
            title: `Edit name for ${level} ${name}`,
            defaultValues: { name },
          },
          type: 'edit',
        });
      }}
      label={label}
      disabled={disabled}
    />
  </ButtonsWrapper>
);
