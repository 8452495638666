import { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { Alert, CircularProgress, Dialog, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { tagsApi } from '../../services/tagsApi';
import Button from '../../shared/components/Button';
import Input from '../../shared/components/Input';
import { IError, getErrorMessage } from '../../shared/utils/getErrorMessage';
import { IOperation } from '../types';

interface IProps {
  isOpen: boolean;
  handleClose: (isOpen: boolean) => void;
  operation: IOperation;
}

const Form = styled.form`
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 350px;
  max-width: 100%;
  button {
    width: 48%;
  }
`;

const ConfirmWrapper = styled.div`
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 350px;
  max-width: 100%;
  button {
    width: 48%;
    margin: auto;
  }
`;

const TagsDialog: FC<IProps> = ({ isOpen, handleClose, operation }) => {
  const { defaultValues = { name: '' }, title, ...data } = operation.data;

  const [createTag, { isSuccess, isLoading, error, reset: resetRequest }] =
    tagsApi.useUpsertTagMutation();

  const [
    deleteTag,
    {
      isSuccess: isDelSuccess,
      isSuccess: isDelLoading,
      error: deleteErr,
      reset: resetDelRequest,
    },
  ] = tagsApi.useRemoveTagMutation();

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, reset, setValue } = methods;

  const onClose = () => {
    handleClose(false);
    reset();
    resetRequest();
    resetDelRequest();
  };

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isDelSuccess]);

  useEffect(() => {
    if (defaultValues.name) {
      setValue('name', defaultValues.name);
    }
  }, [defaultValues, setValue]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {(operation.type === 'add' || operation.type === 'edit') && (
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(({ name }) => createTag({ name, ...data }))}
          >
            <h3>{title} </h3>
            <Input name="name" />
            {error && (
              <Alert
                severity="error"
                sx={{ padding: '0 4px', backgroundColor: 'transparent' }}
              >
                {getErrorMessage([], error as IError)}
              </Alert>
            )}

            <Stack direction="row" justifyContent="space-between">
              <Button variant="outlined" type="button" onClick={onClose}>
                Close
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress style={{ width: '20px', height: '20px' }} />
                ) : (
                  'Submit'
                )}
              </Button>
            </Stack>
          </Form>
        </FormProvider>
      )}
      {operation.type === 'remove' && (
        <ConfirmWrapper>
          <h3>{title}</h3>
          {deleteErr && (
            <Alert
              severity="error"
              sx={{ padding: '0 4px', backgroundColor: 'transparent' }}
            >
              {getErrorMessage([], deleteErr as IError)}
            </Alert>
          )}
          <Button
            onClick={() => deleteTag(data.id!)}
            sx={{ backgroundColor: 'red' }}
            disabled={isDelLoading}
          >
            {isDelLoading ? (
              <CircularProgress style={{ width: '20px', height: '20px' }} />
            ) : (
              'Remove'
            )}
          </Button>
        </ConfirmWrapper>
      )}
    </Dialog>
  );
};

export default TagsDialog;
