import { useEffect, useState } from 'react';
import { getAuthData } from '../../redux/auth/authSelectors';
import { useAppSelector } from '../../redux/hooks';

export default function useAuth() {
  const auth = useAppSelector(getAuthData)
  const [isAuth, setIsAuth] = useState(auth.isAuth);

  useEffect(() => {
    setIsAuth(auth.isAuth)
  }, [auth.isAuth])

  return { isAuth, user: auth.user };
}
