import { TextField, TextFieldProps } from "@mui/material";
import { Controller, FieldValues, RegisterOptions, useFormContext } from "react-hook-form";

type Rules = Omit<RegisterOptions<FieldValues, string>, "disabled" | "setValueAs" | "valueAsNumber" | "valueAsDate">
interface IProps extends Omit<TextFieldProps, "variant"> {
    name: string,
    rules?: Rules
}

const Input = ({ name, label, required = true, rules, ...props }: IProps) => {
    const { control } = useFormContext()

    return <Controller
        name={name}
        control={control}
        rules={{ required: { value: required, message: `${label || "Field"} is required` }, ...rules }}
        render={({ field, fieldState: { error } }) => (
            <TextField
                label={label}
                variant="outlined"
                size="small"
                error={Boolean(error)}
                helperText={error?.message}
                {...field}
                {...props}

            />
        )}
    />
}

export default Input