import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from '../shared/constants';
import { IGroup } from '../shared/types/tags';
import { IUpsertTagData } from '../tags/types';

const { REACT_APP_API_BASE_URL } = process.env;

export const tagsApi = createApi({
  reducerPath: 'tagsReducer',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_BASE_URL,
    credentials: 'include',
  }),
  tagTypes: ['Tag'],
  endpoints: (builder) => ({
    fetchTags: builder.query<IGroup[], string>({
      query: (group = 'all') => ({
        url: `${API_ROUTES.GET_TAGS}?group=${group}`,
      }),
      providesTags: ['Tag'],
      transformResponse: (res: IGroup[]) =>
        res.sort((group1, group2) => group1.name.localeCompare(group2.name)),
    }),
    upsertTag: builder.mutation<IGroup, IUpsertTagData>({
      query: (body) => {
        const { parentId } = body;
        return body.id
          ? { url: API_ROUTES.EDIT_TAG, method: 'POST', body }
          : {
              url: API_ROUTES.CREATE_TAG,
              method: 'POST',
              body: { ...body, parent_id: parentId },
            };
      },
      invalidatesTags: ['Tag'],
    }),
    removeTag: builder.mutation<IGroup, string>({
      query: (id) => ({
        url: API_ROUTES.REMOVE_TAG,
        method: 'POST',
        params: { id },
      }),
      invalidatesTags: ['Tag'],
    }),
  }),
});

export const { reducerPath } = tagsApi;
